<template>
  <div class="create_wrapper fadeIn">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t("addNew") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <!-- Image -->
            <uplode-image
              @inputChanged="uplodeImg_1"
              :placeHolder="$t('forms.labels.image')"
            ></uplode-image>
            <!-- Start:: Is Active -->
            <div class="col-lg-12 py-0">
              <v-checkbox
                :label="$t('forms.labels.is_active')"
                v-model="data.is_active"
                color="#007460"
              ></v-checkbox>
            </div>
            <!-- End:: Is Active -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.ar.name"
                  @keypress="isArabic($event)"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.name_ar") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.en.name"
                  @keypress="isEnglish($event)"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.name_en") }}
                </label>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.ar.slug"
                  @keypress="isArabic($event)"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.slug_ar") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.en.slug"
                  @keypress="isEnglish($event)"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.slug_en") }}
                </label>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <textarea
                  class="form-control"
                  v-model="data.ar.desc"
                  @keypress="isArabic($event)"
                ></textarea>
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.desc_ar") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <textarea
                  class="form-control"
                  v-model.trim="data.en.desc"
                  @keypress="isEnglish($event)"
                ></textarea>
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.desc_en") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @keypress="preventMinus"
                  v-model.trim="data.ordering"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.ordering") }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="buttons_wrapper">
          <button class="button_style_1" :disabled="btnIsLoading">
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.brands.title"),
          disabled: false,
          href: "/brands/show-all",
        },
        {
          text: this.$t("breadcrumb.brands.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data
      data: {
        image: null,
        ar: {
          name: null,
          slug: null,
          desc: null,
        },
        en: {
          name: null,
          slug: null,
          desc: null,
        },
        ordering: null,
        is_active: true,
      },
    };
  },

  methods: {
    // Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;

      if (!this.data.image) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.image"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.ar.name || !this.data.en.name) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.name"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;

        // } else if (!this.data.ordering) {
        //   this.$iziToast.error({
        //     timeout: 2000,
        //     message: "  الترتيب مطلوب",
        //     position: "bottomRight",
        //   });
        //   this.btnIsLoading = false;
        //   return;
      } else if (!this.data.ar.desc || !this.data.en.desc) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.desc"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.submitData();
        return;
      }
    },
    uplodeImg_1(obj) {
      this.data.image = obj;
    },
    // Submit Data
    submitData() {
      const submit_data = new FormData();
      submit_data.append("ar[name]", this.data.ar.name);
      submit_data.append("en[name]", this.data.en.name);
      submit_data.append("ar[slug]", this.data.ar.slug);
      submit_data.append("en[slug]", this.data.en.slug);
      submit_data.append("ar[desc]", this.data.ar.desc);
      submit_data.append("en[desc]", this.data.en.desc);
      submit_data.append("image", this.data.image.img_file);
      submit_data.append("is_active", +this.data.is_active);
      submit_data.append(
        "ordering",
        this.data.ordering ? this.data.ordering : ""
      );

      this.$axios({
        method: "POST",
        url: "brands",
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "bottomRight",
          });
          this.$router.push({ path: "/brands/show-all" });
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
          this.btnIsLoading = false;
        });
    },

    getCategories() {
      this.$axios({
        method: "GET",
        url: `get_all_category`,
      }).then((res) => {
        this.categories = res.data.data.map((el) => ({
          id: el.id,
          name: el.ar.label,
        }));
      });
    },

    // selectUnique(ev) {
    //   if (!ev || ev.length < this.data.categories.length) {
    //     this.data.categories = ev;
    //     return;
    //   }

    //   let newValue = ev.filter(
    //     (x) => this.data.categories.indexOf(x) === -1
    //   )[0];
    //   let group = this.getGroupByLib(newValue);
    //   if (this.value.some((x) => this.getGroupByLib(x) === group)) {
    //     this.data.categories = this.data.categories.filter(
    //       (x) => this.getGroupByLib(x) !== group
    //     );
    //     this.data.categories.push(newValue);
    //   } else this.data.categories = ev;
    // },
    // getGroupByLib(lib) {
    //   return this.categories.filter((x) =>
    //     x.children.some((y) => y.name === lib.name)
    //   )[0].name;
    // },
  },

  mounted() {
    this.getCategories();
  },
};
</script>
